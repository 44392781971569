import React, { createContext, useState } from 'react'

const defaultState = {
  applicationName: 'Application',
  username: 'user',
}

interface GlobalState {
  applicationName: string
  username: string
}

export const GlobalStateContext = createContext<
  [GlobalState, React.Dispatch<React.SetStateAction<GlobalState>>]
>([defaultState, () => {}])

export const GlobalStateContextProvider: React.FC = ({ children }) => {
  const [globalState, setGlobalState] = useState(defaultState)

  return (
    <GlobalStateContext.Provider value={[globalState, setGlobalState]}>
      {children}
    </GlobalStateContext.Provider>
  )
}

export default GlobalStateContextProvider
