/**
 * Gets value for a given cookie
 * @param string the cookie name
 * @returns the value if found
 * @returns null if not found
 *
 */
export const getCookie = (cookieName: string) => {
  const cookie =
    document.cookie
      .trim()
      .split(';')
      .find((c) => c.trim().startsWith(`${cookieName}=`)) || ''

  const value = cookie.split('=')[1] ?? null

  return value
}
